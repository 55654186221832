<template>
  <b-modal id="modal-gudang" size="lg" title="Informasi User">
    <b-row class="mx-0">
      <b-col cols="12" class="text-center mb-2">
        <UploadPhoto v-if="!photo_url" :label-for="'upload_photo'"
          :style-name="'width:195px;height:140px;margin: 0 auto;'" :photo-url-parent="photo_url" @photo="getPhoto" />
        <div v-else>
          <div class="box__upload--photo mx-auto" :style="`background-image: url(${photo_url});`"
            @click="photo_url = ''">
            <b-avatar class="floating__close--button">
              <feather-icon class="text-danger" icon="XIcon" />
            </b-avatar>
          </div>
        </div>
      </b-col>
      <!-- Nama -->
      <b-col cols="12" md="12" class="mb-2">
        <label for="v-nama">Nama <span class="text-danger">*</span></label>
        <b-form-group>
          <b-form-input id="v-nama" v-model="formPayload.name" type="text" placeholder="Nama" />
        </b-form-group>
      </b-col>
      <!-- Jabatan -->
      <!-- <b-col cols="12" md="12" class="mb-2">
        <label for="v-jabatan">Jabatan <span class="text-danger">*</span></label>
        <b-form-group>
          <b-form-input id="v-jabatan" v-model="formPayload.jabatan" type="text" placeholder="Jabatan" />
        </b-form-group>
      </b-col> -->
      <!-- Cabang -->
      <!-- <b-col cols="12" md="12" class="mb-2">
        <label for="v-cabang">Cabang <span class="text-danger">*</span></label>
        <b-form-group>
          <v-select id="v-cabang" v-model="formPayload.branch_uuid" label="name" :options="optionsCabang"
            placeholder="Pilih Cabang" @search="onSearchCabang" />
        </b-form-group>
      </b-col> -->
      <!-- Email -->
      <b-col cols="12" md="12" class="mb-2">
        <label for="v-email">Email <span class="text-danger">*</span></label>
        <b-form-group>
          <b-form-input id="v-email" v-model="formPayload.email" type="email" placeholder="Kota" />
        </b-form-group>
      </b-col>
      <!-- Password -->
      <b-col cols="12" md="12" class="mb-2">
        <label for="v-password">Password</label>
        <b-form-group>
          <b-form-input id="v-password" v-model="formPayload.password" type="password" placeholder="Password" />
        </b-form-group>
      </b-col>
      <!-- Konfirmasi Password -->
      <b-col cols="12" md="12" class="mb-2">
        <label for="v-c-password">Konfirmasi Password</label>
        <b-form-group>
          <b-form-input id="v-c-password" v-model="formPayload.password_confirmation" type="password"
            placeholder="Konfirmasi Password" />
        </b-form-group>
      </b-col>
      <div class="form-group">
        <div v-for="permissionItem, key in permissions" :key="key" class="row mt-1 mx-0">
          <div class="col-12">
            <label class="text-dark size14 mb-1"><strong>{{ permissionItem.group_name }}</strong></label>:
          </div>
          <div v-for="permission in permissionItem.permission_list" :key="permission.id" class="col-4 mb-0">
            <div class="form-group">
              <b-form-checkbox :id="'permission_checkbox_' + permission.id" v-model="formPayload.permissions"
                class="text-capitalize text-dark" :value="permission.id"
                placeholder="Enter role name, example: finance">
                {{ permission.name }}
              </b-form-checkbox>
            </div>
          </div>
        </div>
      </div>
    </b-row>
    <template #modal-footer="{}">
      <b-button variant="primary" @click="editUuid == null ? createItem() : updateItem()">
        Simpan
      </b-button>
    </template>
  </b-modal>
</template>

<script>
/* eslint-disable vue/require-default-prop */
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash'
import {
  BModal, BContainer, BRow, BCol, BButton, BFormGroup, BFormInput, BFormCheckbox, BAvatar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import UploadPhoto from '@/components/UploadPhoto.vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BAvatar,
    BContainer,
    UploadPhoto,
    BFormCheckbox,
    vSelect,
  },
  props: {
    formData: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: '',
    },
    createItem: {
      type: Function,
    },
    editItem: {
      type: Function,
    },
    editId: {
      type: Number,
    },
    updateItem: {
      type: Function,
    },
    cleanUpForm: {
      type: Function,
    },
    logo: {
      type: String,
    },
  },
  data() {
    return {
      formPayload: {
        name: '',
        description: '',
        logo: '',
        jabatan: '',
        permissions: [],
      },
      photo_url: '',
      permissions: [],
      editUuid: null,
      optionsCabang: [],
    }
  },
  watch: {
    logo(value) {
      this.photo_url = value
    },
    editId(value) {
      this.editUuid = value
    },
    formData(value) {
      this.formPayload = value
    },
    formPayload: {
      handler(value) {
        this.$emit('getPayload', value)
      },
      deep: true,
    },
  },
  mounted() {
    this.getPermissions()
    this.getCabang()
  },
  destroyed() {
    this.formPayload = {
      name: '',
      description: '',
      logo: '',
      permissions: [],
    }
    this.photo_url = ''
    this.permissions = []
    this.editUuid = null
  },
  methods: {
    onSearchCabang(search, loading) {
      if (search.length) {
        loading(true)
        this.searchCabang(loading, search, this)
      } else {
        loading(true)
        setTimeout(() => {
          this.optionsCabang = []
          this.getCabang()
          loading(false)
        }, 500)
      }
    },
    searchCabang: _.debounce((loading, search, vm) => {
      vm.$store.dispatch('cabang/getData', {
        uuid: '',
        params: {
          search,
        },
      })
        .then(response => {
          // eslint-disable-next-line no-param-reassign
          vm.optionsCabang = []
          response.data.data.data.forEach(item => {
            vm.optionsCabang.push(item)
          })
          loading(false)
        })
    }, 300),
    async getCabang() {
      this.$store.dispatch('cabang/getData', {
        uuid: '',
        params: '',
      }).then(result => {
        this.optionsCabang = result.data.data.data
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
    getPhoto(value) {
      this.formPayload.logo = value.photo
    },
    getPermissions() {
      this.$http.get('super-admin/users/permission')
        .then(result => {
          this.permissions = result.data.data
        }).catch(() => {
        })
    },
    directPage() {
      this.cleanUpForm()
      this.$bvModal.hide('modal-gudang')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.box__upload--photo {

  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #EFF1F5;
  border: 1px dashed #7A7F94;
  border-radius: 8px;
  position: relative;

  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;

  width: 140px;
  height: 140px;

  .floating__close--button {
    position: absolute;
    top: 6px;
    width: 24px;
    height: 24px;
    right: 6px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
</style>
